import IFormElementConfigs from "../IFormElementConfigs";
import FormElementConfig from "../FormElementConfig";
import * as Yup from 'yup';

export class Ua138h_u5_1_2_FormConfig {
    private configs: IFormElementConfigs = {};

    constructor() {
        this.configs["panelGeneral"] = new FormElementConfig({ isVisible: true });
        this.configs["paragraphOfRegulation"] = new FormElementConfig({ isVisible: true, yupSchema: Yup.string().required() });
        this.configs["id"] = new FormElementConfig({ isVisible: true, yupSchema: Yup.string() });
        this.configs["ordinalNumber"] = new FormElementConfig({ isVisible: true, yupSchema: Yup.string() });
        this.configs["sourceIdentifier"] = new FormElementConfig({ isVisible: true, yupSchema: Yup.string() });

        this.configs["panelCorrectionInformations"] = new FormElementConfig({ isVisible: true });
        this.configs["correctionReason"] = new FormElementConfig({
            isVisible: true, yupSchema: Yup.string()
                .when('isCorrective', {
                    is: true,
                    then: Yup.string().required(),
                    otherwise: Yup.string(),
                })
        });
        this.configs["nextCorrectionOrdinalNumber"] = new FormElementConfig({
            isVisible: true, yupSchema: Yup.string()
                .when('isCorrective', {
                    is: true,
                    then: Yup.string().required(),
                    otherwise: Yup.string(),
                })
        });
        this.configs["correctionOrdinalNumber"] = new FormElementConfig({
            isVisible: true, yupSchema: Yup.string()
                .when('nextCorrectionOrdinalNumber', {
                    is: () => 1,
                    then: Yup.string(),
                    otherwise: Yup.string().required(),
                })
        });
        this.configs["editorUserName"] = new FormElementConfig({
            isVisible: true, yupSchema: Yup.string()
                .when('isCorrective', {
                    is: true,
                    then: Yup.string().required(),
                    otherwise: Yup.string(),
                })
        });

        this.configs["panelProduct"] = new FormElementConfig({ isVisible: true });
        this.configs["productName"] = new FormElementConfig({ isVisible: true, yupSchema: Yup.string().required() });
        this.configs["cnCode"] = new FormElementConfig({ isVisible: true, yupSchema: Yup.string().required() });
        this.configs["incomeInExciseUnit"] = new FormElementConfig({ isVisible: true, yupSchema: Yup.string().required() });
        this.configs["expenditureInExciseUnit"] = new FormElementConfig({ isVisible: false });

        this.configs["panelAdditionalInformations"] = new FormElementConfig({ isVisible: true });
        this.configs["date"] = new FormElementConfig({ isVisible: true, yupSchema: Yup.string().required() });
        this.configs["documentDate"] = new FormElementConfig({ isVisible: true });
        this.configs["documentNumber"] = new FormElementConfig({ isVisible: true });
        this.configs["productNumber"] = new FormElementConfig({ isVisible: true });
        this.configs["invoiceDate"] = new FormElementConfig({ isVisible: true, yupSchema: Yup.string().required() });
        this.configs["invoiceNumber"] = new FormElementConfig({ isVisible: true, yupSchema: Yup.string().required() });
        this.configs["typeOfLocalDocument"] = new FormElementConfig({ isVisible: true })
        this.configs["localDocumentNumber"] = new FormElementConfig({ isVisible: true })
        this.configs["exciseAmount"] = new FormElementConfig({ isVisible: true });
        this.configs["amountRate"] = new FormElementConfig({ isVisible: true });
        this.configs["saleDate"] = new FormElementConfig({ isVisible: false });
        this.configs["forPeriodFrom"] = new FormElementConfig({ isVisible: true });
        this.configs["forPeriodTo"] = new FormElementConfig({ isVisible: true });
        this.configs["comment"] = new FormElementConfig({ isVisible: true });

        this.configs["panelSupplier"] = new FormElementConfig({ isVisible: true });
        this.configs["supplier"] = new FormElementConfig({ isVisible: true });
        this.configs["supplierIdentifier"] = new FormElementConfig({ isVisible: true });
        this.configs["supplierName"] = new FormElementConfig({ isVisible: true });
        this.configs["supplierStreetName"] = new FormElementConfig({ isVisible: true });
        this.configs["supplierStreetNumber"] = new FormElementConfig({ isVisible: true });
        this.configs["supplierPostcode"] = new FormElementConfig({ isVisible: true });
        this.configs["supplierCity"] = new FormElementConfig({ isVisible: true });

        this.configs["panelReceipient"] = new FormElementConfig({ isVisible: false });
        this.configs["receipient"] = new FormElementConfig({ isVisible: false });
        this.configs["receipientIdentifier"] = new FormElementConfig({ isVisible: false });
        this.configs["receipientName"] = new FormElementConfig({ isVisible: false });
        this.configs["receipientStreetName"] = new FormElementConfig({ isVisible: false });
        this.configs["receipientStreetNumber"] = new FormElementConfig({ isVisible: false });
        this.configs["receipientPostcode"] = new FormElementConfig({ isVisible: false });
        this.configs["receipientCity"] = new FormElementConfig({ isVisible: false });
    }

    public get(): IFormElementConfigs {
        return this.configs;
    }
}